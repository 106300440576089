import { useAlignment } from "@/api/alignment/hooks";
import {
  CDSData,
  ProteinSearchResponseOutput,
  taxonomyToLinageString,
  UNANNOTATED_CLIP_LABEL,
} from "@/api/protein_search/schemas";
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "@/components/ui/accordion";
import { Button } from "@/components/ui/button/button";
import { InfoIcon } from "lucide-react";
import { GenomicContextViz } from "../GenomicContextViz/GenomicContextViz";
import { Tooltip, TooltipContent, TooltipTrigger } from "../ui/tooltip";
import { ActiveCard } from "./ActiveCard";
import { usePaginator } from "@/hooks/usePaginator";
import { cn } from "@/utils/strings";
import { CaretLeftIcon, CaretRightIcon } from "@radix-ui/react-icons";
import DownloadButtons from "../DownloadButtons";

export const PaginatedAccordion = ({
  proteinSearchData,
}: {
  proteinSearchData: ProteinSearchResponseOutput;
}) => {
  const { currentPage, totalPages, nextPage, prevPage, currentDataSlice } =
    usePaginator({
      data: proteinSearchData?.match_data ?? [],
      resultsPerPage: 10,
    });
  const { alignmentData, isFetchingAlignment, alignmentError } = useAlignment({
    query: proteinSearchData.query.record.sequence,
    match_list: proteinSearchData?.match_data?.map((record) => record.match),
  });

  const findAlignmentByCDSData = (cdsData: CDSData) => {
    const res =
      alignmentData?.records.find((result) => {
        return result.refCDSData.cds_shorthand === cdsData.cds_shorthand;
      }) ?? null;
    return res;
  };

  return (
    <Accordion type="multiple" className="flex flex-col justify-start gap-4 ">
      <header className="flex flex-wrap items-end justify-between  gap-x-8 gap-y-2 ">
        <span className="flex flex-col gap-1">
          <h4 className="font-semibold">
            {proteinSearchData.match_data.length} Search result
            {proteinSearchData.match_data.length > 1 ? "s" : ""}
          </h4>
          <span className="flex items-center ">
            <p className="mr-8 text-xs">
              Page {currentPage} of {totalPages}
            </p>

            <Button
              onClick={prevPage}
              disabled={currentPage === 1}
              className="mr-2"
              variant="outline"
              size="xs"
            >
              <CaretLeftIcon className="h-4 w-4" />
            </Button>
            <Button
              onClick={() => {
                nextPage();
              }}
              disabled={currentPage === totalPages}
              className="ml-2"
              variant="outline"
              size="xs"
            >
              <CaretRightIcon className="h-4 w-4" />
            </Button>
          </span>
        </span>
        <span>
          <DownloadButtons data={proteinSearchData} />
        </span>
      </header>
      {currentDataSlice.map((datum) => {
        return (
          <AccordionItem
            key={JSON.stringify(datum.match.cdsData)}
            value={datum.match.cdsData.cds_shorthand}
            className={cn("rounded-md bg-molstar px-4")}
          >
            <AccordionTrigger className="group -mt-3 flex items-start justify-start !pb-1">
              <div className="flex w-full flex-col items-center">
                <span className="flex w-full items-baseline gap-4 font-semibold">
                  {datum.match.clipAnnotation?.clipDescription ??
                    UNANNOTATED_CLIP_LABEL}{" "}
                  <p className="text-xs text-noir-400">
                    {datum.score.toFixed(3)}
                  </p>
                  <Tooltip>
                    <TooltipTrigger asChild>
                      <Button size="xs" variant="ghost" className="!px-0">
                        <InfoIcon className="h-3 w-3 text-noir-600" />
                      </Button>
                    </TooltipTrigger>
                    <TooltipContent side="right">
                      <p className="!font-normal">
                        Closest Swiss-Prot annotation with cosine similarity
                        score
                      </p>
                    </TooltipContent>
                  </Tooltip>
                </span>
                <div className="w-full">
                  <p
                    className={cn(
                      "text-left text-xs font-light group-data-[state=open]:hidden",
                    )}
                  >
                    {datum.taxonomy.phylum}
                  </p>
                  <p
                    className={cn(
                      "text-left text-xs font-light group-data-[state=closed]:hidden",
                    )}
                  >
                    {taxonomyToLinageString(datum.taxonomy)}
                  </p>
                </div>
                <GenomicContextViz
                  className="w-full px-1 py-1  group-data-[state=open]:hidden"
                  disablePopover={true}
                  items={[datum.match, ...datum.contig].map((record) => ({
                    id: record.cdsData.cds_shorthand,
                    stack: record.cdsData.strand === "forward" ? 0 : 1,
                    start: record.cdsData.start,
                    end: record.cdsData.end,
                    strand: record.cdsData.strand,
                    sequence: record.sequence,
                    clipAnnotation: record.clipAnnotation,
                    isMatch:
                      datum.match.cdsData.cds_shorthand ===
                      record.cdsData.cds_shorthand,
                    operonPrediction: record.operonPrediction,
                  }))}
                />
              </div>
            </AccordionTrigger>
            <AccordionContent className="py-4">
              <ActiveCard
                match={datum.match}
                contig={datum.contig}
                alignment={findAlignmentByCDSData(datum.match.cdsData)}
                isFetchingAlignment={isFetchingAlignment}
                alignmentError={alignmentError}
                size="full"
              />
            </AccordionContent>
          </AccordionItem>
        );
      })}
    </Accordion>
  );
};
