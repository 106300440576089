// Normally, we can't interpolate tailwind classnames, because the classes are statically generated.
// To get around this, we need to make sure all possible classes are generated by updating the tailwind config.

import { strIntoArray } from "./arrays";

// So to add <new-color> to the tailwind config, we need to add "bg-<new-color>" to the colors object in tailwind.config.js
const DEFAULT_COLORS = [
  "#001219",
  "#005f73",
  "#0a9396",
  "#94d2bd",
  "#e9d8a6",
  "#ee9b00",
  "#ca6702",
  "#bb3e03",
  "#ae2012",
  "#9b2226",
  "#f72585",
  "#b5179e",
  "#7209b7",
  "#560bad",
  "#480ca8",
  "#3a0ca3",
  "#3f37c9",
  "#4361ee",
  "#4895ef",
  "#4cc9f0",
];

export const strToColor = (str: string) => {
  return strIntoArray(str, DEFAULT_COLORS);
};

export const QUERY_COLOR = "#ff0000";
export const RETRIEVAL_COLOR = "#2edd97";
