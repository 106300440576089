import { useQuery } from "@tanstack/react-query";

import { ProteinRecord } from "@/api/protein_search/schemas";
import { gaiaAPI } from "@/utils/wretch";
import { HMMAnnotateRequestSchema, HMMAnnotateResponseSchema } from "./schemas";
import { useToken } from "@/api/auth/hooks";
import { useNavigate } from "react-router-dom";

export const useHMMAnnotate = ({ record }: { record?: ProteinRecord }) => {
  const token = useToken();
  const navigate = useNavigate();
  const queryFn = async () => {
    if (!record) {
      return null;
    }
    const apiPayload = HMMAnnotateRequestSchema.parse({
      sequences: [record],
    });

    const res = await gaiaAPI
      .auth(`Bearer ${token}`)
      .url("/hmm_annotate")
      .post(apiPayload)

      .unauthorized(() => {
        alert("Your session has expired, please login again");
        navigate("/login");
      })

      .json();

    const output = HMMAnnotateResponseSchema.parse(res);
    return output;
  };

  const { data, isFetching, error } = useQuery({
    queryKey: ["hmm_annotate", record?.sequence],
    queryFn: queryFn,
    enabled: !!record,
  });

  return {
    hmmAnnotateData: data,
    isFetchingHMMAnnotate: isFetching,
    hmmAnnotateError: error as Error | null,
  };
};
