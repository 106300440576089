import { LoginPage } from "@/pages/Auth/LoginPage";
import SignupPage from "@/pages/Auth/SignupPage";
import { FourOhFour } from "@/pages/FourOhFour/FourOhFour";
import { HomePage } from "@/pages/HomePage/HomePage";
import { SearchPage } from "@/pages/SearchPage/SearchPage";
import { ProtectedRoute } from "@/providers/auth/ProtectedRoute";
import { Route, Routes } from "react-router-dom";

const App: React.FC = () => {
  return (
    <Routes>
      <Route
        path="/"
        element={
          <ProtectedRoute>
            <HomePage />
          </ProtectedRoute>
        }
      />
      <Route path="/login" element={<LoginPage />} />
      <Route path="/signup" element={<SignupPage />} />
      <Route
        path="/search/:sequence"
        element={
          <ProtectedRoute>
            <SearchPage />
          </ProtectedRoute>
        }
      />
      // catch-all route
      <Route path="*" element={<FourOhFour />} />
    </Routes>
  );
};

export default App;
