import { AuthContext } from "@/providers/auth/AuthContext";
import { useContext } from "react";
import { useNavigate } from "react-router-dom";

export const useAuth = () => {
  return useContext(AuthContext);
};

export const useToken = () => {
  const { token } = useAuth();
  const navigate = useNavigate();
  if (token === null) {
    alert(
      "You must be logged in to access this page. Redirecting to login page...",
    );
    navigate("/login");
  }
  return token;
};
