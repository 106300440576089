import { useAuth } from "@/api/auth/hooks";
import { Navigate } from "react-router-dom";

export const ProtectedRoute = ({ children }: { children: React.ReactNode }) => {
  const authState = useAuth();
  const user = authState?.user;
  if (!user) {
    // user is not authenticated
    return <Navigate to="/login" />;
  }
  return children;
};
