import { Link } from "react-router-dom";
import { Button } from "./button/button";
import { cn } from "@/utils/strings";

export const Footer = ({ className }: { className?: string }) => {
  const navigation = [
    {
      name: "About",
      href: "https://www.tatta.bio/",
    },
    {
      name: "Terms of Use",
      href: "https://www.tatta.bio/terms-of-use",
    },
    {
      name: "Privacy Policy",
      href: "https://www.tatta.bio/privacy-policy",
    },
    {
      name: "FAQ",
      href: "https://www.tatta.bio/gaia-faqs",
    },
  ];

  return (
    <footer
      className={cn(
        "mx-auto flex w-full max-w-7xl items-center justify-between justify-between px-6",
        className,
      )}
    >
      <p className="mt-8 text-center text-xs md:order-1 md:mt-0">
        &copy; 2024 Tatta Bio, Inc. All rights reserved.
      </p>
      <div className="flex gap-x-6 ">
        {navigation.map((item) => (
          <Button variant="link" key={item.name} className="!px-0 font-normal">
            <Link to={item.href} className="text-xs">
              <span>{item.name}</span>
            </Link>
          </Button>
        ))}
      </div>
    </footer>
  );
};
