import { AuthContextType } from "@/api/auth/schemas";
import { createContext } from "react";

const defaultAuthContext: AuthContextType = {
  user: null,
  token: null,
  login: () => Promise.resolve(null),
  logout: () => {},
  signup: () => Promise.resolve({ message: "", success: false }),
};
export const AuthContext = createContext(defaultAuthContext);
