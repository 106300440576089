import {
  OperonPrediction,
  UNANNOTATED_CLIP_LABEL,
} from "@/api/protein_search/schemas";
import { CopyButton } from "@/components/SearchResults/CopyButton";
import { Button } from "@/components/ui/button/button";
import { Popover, PopoverContent } from "@/components/ui/popover";
import { cn } from "@/utils/strings";
import { MagnifyingGlassIcon } from "@radix-ui/react-icons";
import { PopoverTrigger } from "@radix-ui/react-popover";
import { SquareArrowOutUpRightIcon } from "lucide-react";
import { Link } from "react-router-dom";
import { Tooltip, TooltipContent, TooltipTrigger } from "../ui/tooltip";

interface AlignmentItem {
  id: string;
  start: number;
  end: number;
  isMatch: boolean;
  strand: "forward" | "reverse";
  operonPrediction: OperonPrediction | null;
  sequence: string;
  clipAnnotation: {
    clipId: string;
    clipDescription: string;
  } | null;
}
export const GenomicContextViz = ({
  items,
  disablePopover,
  className,
}: {
  items: AlignmentItem[];
  disablePopover?: boolean;
  className?: string;
}) => {
  const minStart = Math.min(...items.map((item) => item.start));
  const maxEnd = Math.max(...items.map((item) => item.end));
  const forwardStack = items.filter((item) => item.strand === "forward");
  const reverseStack = items.filter((item) => item.strand === "reverse");
  const stacks = [forwardStack, reverseStack];
  return (
    <div className={cn("", className)}>
      <div className="relative mx-1 max-w-full pb-5">
        {stacks.map((items, i) => (
          <div className={cn(i == 0 && "pb-5")} key={`stack-${i}`}>
            {items.map((m, j) => (
              <ContextArrow
                item={m}
                idx={j}
                key={`${m.id}-${j}`}
                sequenceStart={minStart}
                sequenceEnd={maxEnd}
                isMatch={m.isMatch}
                disablePopover={disablePopover}
              />
            ))}
          </div>
        ))}
      </div>
    </div>
  );
};

type ArrowColor = {
  body: string;
  leftArrow: string;
  rightArrow: string;
};

const matchColor: ArrowColor = {
  body: "bg-brand-400",
  leftArrow: "border-r-brand-400",
  rightArrow: "border-l-brand-400",
};
const baseColor: ArrowColor = {
  body: "bg-zinc-500/80",
  leftArrow: "border-r-zinc-500/80",
  rightArrow: "border-l-zinc-500/80",
};

const operonColors: ArrowColor[] = [
  {
    body: "bg-sky-300/70",
    leftArrow: "border-r-blue-300/70",
    rightArrow: "border-l-blue-300/70",
  },
  {
    body: "bg-rose-300/70",
    leftArrow: "border-r-red-300/70",
    rightArrow: "border-l-red-300/70",
  },
  {
    body: "bg-indigo-300/70",
    leftArrow: "border-r-indigo-300/70",
    rightArrow: "border-l-indigo-300/70",
  },
  {
    body: "bg-amber-300/70",
    leftArrow: "border-r-amber-300/70",
    rightArrow: "border-l-amber-300/70",
  },
  {
    body: "bg-orange-300/70",
    leftArrow: "border-r-orange-300/70",
    rightArrow: "border-l-orange-300/70",
  },
  {
    body: "bg-cyan-300/70",
    leftArrow: "border-r-cyan-300/70",
    rightArrow: "border-l-cyan-300/70",
  },
  {
    body: "bg-fuchsia-300/70",
    leftArrow: "border-r-fuchsia-300/70",
    rightArrow: "border-l-fuchsia-300/70",
  },
];

const ContextArrow = ({
  item,
  idx,
  sequenceStart,
  sequenceEnd,
  isMatch,
  disablePopover,
}: {
  item: AlignmentItem;
  idx: number;
  sequenceStart: number;
  sequenceEnd: number;
  isMatch: boolean;
  disablePopover?: boolean;
  className?: string;
}) => {
  const left =
    ((item.start - sequenceStart) / (sequenceEnd - sequenceStart)) * 100;
  const width = ((item.end - item.start) / (sequenceEnd - sequenceStart)) * 100;
  let arrowColor = baseColor;
  if (item.isMatch) {
    arrowColor = matchColor;
  } else if (item.operonPrediction) {
    arrowColor =
      operonColors[item.operonPrediction.operonId % operonColors.length];
  }

  return (
    <Popover>
      <PopoverTrigger asChild>
        <button
          className={cn(
            "group absolute cursor-pointer py-2 hover:scale-[110%]",
            "transform transition-transform duration-200 ease-in-out",
            isMatch && "!z-[10]", // want to display the match on top of everything
            isMatch && "outline",
            arrowColor.body,
            "opacity-80 hover:opacity-100",
          )}
          style={{
            left: `${left}%`,
            width: `calc(${width}% - 12px)`,
            zIndex: item.strand === "forward" ? 100 - idx : idx,
          }}
        >
          <Tooltip>
            <TooltipTrigger className="absolute inset-0" />
            <TooltipContent>
              {item.clipAnnotation?.clipDescription ?? UNANNOTATED_CLIP_LABEL}
            </TooltipContent>
          </Tooltip>

          <div
            className={cn(
              "absolute bottom-0 left-0 -translate-x-full",
              "border-y-8 border-l-0 border-r-8 border-solid border-y-transparent ",
              item.strand === "forward"
                ? "hidden before:hidden"
                : "block before:block",
              arrowColor.leftArrow,
              // draw border around arrow head for match
              isMatch &&
                "before:absolute before:w-[12px] before:-rotate-45 before:border-[0.8px] before:border-t before:border-black",
              isMatch && "before:-left-[3.1px] before:-top-[4.8px]",
              isMatch &&
                "after:absolute after:w-[12px] after:rotate-45 after:border-[0.8px] after:border-t after:border-black",
              isMatch && "after:-bottom-[4.8px] after:-left-[3.1px]",
            )}
          />
          <div
            className={cn(
              "absolute bottom-0 right-0 translate-x-full",
              "border-y-8 border-l-8 border-r-0 border-solid border-y-transparent",
              item.strand === "reverse" ? "hidden" : "block",
              arrowColor.rightArrow,
              // draw border around arrow head for match
              isMatch &&
                "before:absolute before:w-[12px] before:rotate-45 before:border-[0.8px] before:border-t before:border-black",
              isMatch && "before:-right-[3.1px] before:-top-[4.8px]",
              isMatch &&
                "after:absolute after:w-[12px] after:-rotate-45 after:border-[0.8px] after:border-t after:border-black",
              isMatch && "after:-bottom-[4.8px] after:-right-[3.1px]",
            )}
          />
        </button>
      </PopoverTrigger>
      <PopoverContent className={cn(disablePopover && "hidden")}>
        <div className="flex flex-col items-center justify-center gap-2">
          <p className=" flex-1">
            {item.clipAnnotation?.clipDescription ?? UNANNOTATED_CLIP_LABEL}
          </p>
          <span className="flex gap-2">
            <CopyButton textToCopy={() => item.sequence} label={"Sequence"} />
            {item.clipAnnotation && (
              <Tooltip>
                <TooltipTrigger>
                  <Button
                    size="xs"
                    variant="outline"
                    className="flex items-center gap-1 rounded-md border px-2 py-1 text-xs "
                    asChild
                  >
                    <a
                      href={`https://www.uniprot.org/uniprot/${item.clipAnnotation.clipId}`}
                      target="_blank"
                    >
                      Annotation
                      <SquareArrowOutUpRightIcon className="h-3 w-3 text-noir-600" />
                    </a>
                  </Button>
                </TooltipTrigger>
                <TooltipContent>
                  <p>Closest Swissprot Entry</p>
                </TooltipContent>
              </Tooltip>
            )}
            <Tooltip>
              <TooltipTrigger>
                <Button
                  size="xs"
                  variant="outline"
                  className="flex items-center gap-1 rounded-md border px-2 py-1 text-xs "
                  asChild
                >
                  <Link to={`/search/${item.sequence}`} target="_blank">
                    <MagnifyingGlassIcon className="h-3 w-3 text-noir-600" />
                    Gaia
                  </Link>
                </Button>
              </TooltipTrigger>
              <TooltipContent align="end">
                <p>Search on Gaia</p>
              </TooltipContent>
            </Tooltip>
          </span>
        </div>
      </PopoverContent>
    </Popover>
  );
};
