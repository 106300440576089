import { Button } from "@/components/ui/button/button";
import { cn } from "@/utils/strings";
import { MagnifyingGlassIcon } from "@radix-ui/react-icons";
import { Link } from "react-router-dom";
import { Tooltip, TooltipContent, TooltipTrigger } from "./ui/tooltip";

export const ExampleSeqsGrid = ({ className }: { className?: string }) => {
  const examples = [
    {
      title: "A0Reaction center protein L,M chains fusion",
      seq: "MSDTPRAPQQQPDEEPRAQSGQASQSVAPGTQPSSTPTTDPEVLPLSVVEEYYKRPGRTMLASIFGFEPFDFWFGRFYVGFFGILVMLGTILGVTFYFYEMLIVEGTYNILAARIDPPPISDGLRLVTPGEPGGSWIVIMIAATIAFVAWALRQVDISRKLELGYEVPLAFGAVVSSWITLQWLRPIAMGAWGNGFPLGITHHLDWVSNIGYQYYNFFYNPFHAVGVTLLFASTLFLAMHGSAILSAARRPVTDRHVDTFWRQLFGYSVGEIGIHRVAFWAGATSVIVSNLCIFLSGTLVQDWNAFYSFWDGMPIWSFSGAGLALFGIGLVFARRRDEKPVDPDEIEDLDARGLDGNIGRPFYLPPILQRFVGKDGIRVFPTYLGVWGIISVVTGSVAALIILLDYLLQVGYNPILFVREFFNLALMPPAKEYGLGFAPWNEGGSYLVATFFLHISVLAWWGRVYTRAKATGLGTHLAWGFAAALSLYFVIYLIRPIAMGRWSESAGHGFRAILDWTNYVSILHGNFYYNPFHMLSIFFLLGSTLLLAMHGATILAAEPYKAEREVDEMRVEGTGTQRAQLLWRWVMGFNANSLTIHQWAWWFAGLCGITGAIGLLLSGTLIWDWYAWAIDARIVSPWPNPDWAQYLPR",
    },
    {
      title: "Faecalibacterium phage Terminase",
      seq: "MNARRGLKSWLVTTMSENAAVDRLNALVSKLVAAIRPPPNVSVSEWATQNRVLSPEASAEQGRWRNSRTPYLVEIMDAFSDPRIHHIVVVASSQVGKSEFENNVIGRTIDVDPGSILFIHPQMTDAKEYSKLRIAPMIRDCPTLRAKVAEKKSRDSGNTILQKSYPGGILTMCGSTEAHALASKPIRYVLGDERDRWAVSAGTEGDPWELAMARQTTFYNAKAVEVSTPTIKGHSAIAKSYVKGTMERWVSQCPHCKGFHELRWEDIRYEYDTIETHGEKTYKVKDVWYLCPECACISDEVTMKRAPAHWQAENPAAYENGIRSFWLNSFVSQWAAWKDTVLKYLNALGDTKKMQVVYNTRLGLLWEDRGDVQDEDTMLGRREEYPAELPDGVLVLTAGVDTQDDRMEYEIVGFGHFGETWGIEKGIISGRPDSDEVWQQLDELVFDRKLKFADGLELPVSIKFVDEGGHFTQEVRQRCHDRIGKKVFCIKGFPGSDRPFTGPPKQVKITVQNRYIGMCWQYQLGVDAGKQIIMDDLKVQEPGPRYCHFPRRDDYGLGYFNGLLSEHLIYKEGHRNPWQWDKISGHERNEPLDCRNYAIAAYKVLPKDLDAIDRALKRLRGKAPEAPAAPVINIQQPVSRPQPSPGRRRENFLDDW",
    },
    {
      title: "Reaction center protein L chain (Fragment)",
      seq: "ATLGTLLIIWGAAMGPTWNPWQISIAPPDLSYGLNMAPLTEGGLWQFITFCAIGAFVSWALRQAEIARKLGMGLHVPIAFGFAILAYVTLVVIRPVLLGAWGHGFPYGIFSHLDWVSNVGYQYLHFHYNPAHMLAITFFFTNALALALHGSLILSMTNPAKGEPVKTGEHENTFFRDLVGYSIGALGIHRLGLFLAINAAFWSAVCIVISGPFWTRGWPEWWNWWLELPFWS",
    },
    {
      title: "Carbon monoxide dehydrogenase CooS",
      seq: "MGKEMKQTAFEKSIDSASQIMLQKAEDEGIETAWDRYEQQLPQCSFGQLGICCRNCNMGPCRIDPFGEGTEKGICGATADIIVARNLLRMIAAGAAAHSDHARDAVLTFKKMSEGKAGSYRIKDEAKLYSLASEYGISAEEKSREEVAVELASALLSEFGKQEGPILCTKRAPESRLKLWSELGIEPRGIDREIVECMHRTHIGVDNDATHILLHGLRTSLSDGWGGSMIATEIQDVLFGTPEPKKSTVNLGVLSHDKVNVIVHGHEPILSEMIVEAAEDPELLELAEEKGATGINVAGICCTGNETLMRHGTPIAGTFLQQELAVITGAVEAMVVDVQCIMPSLGNLTGCYHTKFISTSPKADFPGTARMEFHEEEAYATAKEIVKAAVENFPNRNLKKVSIPEEKQECMVGFSAEAILKALGGSPAPLIEAIAGGAIKGIGAVVGCNNVKIQHNYGHVNLVKELIKNNVLVVTTGCNAIACAEAGLLVPEAAALAGDGLKGVCEALGIPPVLHMGSCVDISRILVLASAVANSLGVDISDLPAAGAAPEWMSEKAVSIGAYVVSSGVFTVLGTIPPVLGSQAVTALLTKGLDGVIGASFAVEPDPFKAADLMLEHIEGKRKALGLK",
    },
  ];

  return (
    <div className={cn("flex flex-col gap-2", className)}>
      <h4 className="col-span-2 text-lg font-semibold">Examples</h4>
      <div className={cn("grid grid-cols-1 gap-3", className)}>
        {examples.map((example) => (
          <Tooltip key={example.title}>
            <TooltipTrigger asChild>
              <Link to={`/search/${example.seq}`}>
                <div
                  className={cn(
                    "flex  items-center justify-between gap-2",
                    "rounded-md bg-molstar px-4 py-1",
                    "text-xs transition-transform duration-200 ease-in-out hover:scale-105",
                  )}
                >
                  <h2>{example.title}</h2>
                  <Button
                    size="xs"
                    variant="outline"
                    className="flex items-center gap-1 rounded-md border border px-2 py-1 text-xs"
                  >
                    <MagnifyingGlassIcon className="h-3 w-3 text-noir-600" />
                  </Button>
                </div>
              </Link>
            </TooltipTrigger>
            <TooltipContent align="end">
              <p>Search on Gaia</p>
            </TooltipContent>
          </Tooltip>
        ))}
      </div>
    </div>
  );
};
