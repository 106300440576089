import { z } from "zod";

export const UserSchema = z.object({
  emailAddress: z.string().email("Invalid email address"),
  isActive: z.boolean(),
});
export type User = z.infer<typeof UserSchema>;

export const SignupRequestSchema = z.object({
  emailAddress: z.string().email("Invalid email address"),
  firstName: z.string().min(2),
  lastName: z.string().min(2),
  affiliation: z.string().min(2),
});
export type SignupRequest = z.infer<typeof SignupRequestSchema>;

export const SignupResponseSchema = z.object({
  message: z.string(),
  success: z.boolean(),
});
export type SignupResponse = z.infer<typeof SignupResponseSchema>;

export const LoginRequestSchema = z.object({
  emailAddress: z.string().email("Invalid email address"),
});
export type LoginRequest = z.infer<typeof LoginRequestSchema>;

export const LoginResponseSchema = z.object({
  accessToken: z.string(),
  user: UserSchema,
  tokenType: z.literal("bearer"),
});
export type LoginResponse = z.infer<typeof LoginResponseSchema>;

export interface AuthContextType {
  user: User | null;
  token: string | null;
  login: (data: LoginRequest) => Promise<LoginResponse | null>;
  signup: (data: SignupRequest) => Promise<SignupResponse>;
  logout: () => void;
}
