import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { Footer } from "../ui/footer";
import { Input } from "../ui/input";
import { SearchIcon } from "lucide-react";
import {
  cn,
  doesSequenceHaveInvalidChars,
  isSeqTooLong,
} from "@/utils/strings";

export const Shell = ({
  children,
  noSearch,
  noLogo,
  className,
}: {
  children: React.ReactNode;
  noSearch?: boolean;
  noLogo?: boolean;
  className?: string;
}) => {
  const [sequence, setSequence] = React.useState<string | null>(null);
  const navigate = useNavigate();
  const onSubmit = () => {
    if (isSeqTooLong(sequence) || doesSequenceHaveInvalidChars(sequence)) {
      alert("Invalid sequence");
    } else {
      navigate(`/search/${sequence}`);
    }
  };
  return (
    <div className={cn("flex min-h-screen w-full flex-col !bg-noir-300 ")}>
      <header
        className={cn(
          "sticky top-0 z-[100] flex h-14 items-center gap-1 px-6 ",
          "backdrop-blur-md ",
          className,
        )}
      >
        <>
          <img src="/tatta_logo.svg" className="h-12" />
          {!noLogo && (
            <Link to="/">
              <h1 className="text-2xl font-semibold tracking-wider text-brand-600 ">
                Gaia
              </h1>
            </Link>
          )}
        </>

        {noSearch ? null : (
          <form
            className="relative ml-auto flex w-full w-full max-w-md items-center "
            onSubmit={onSubmit}
          >
            <Input
              className=" w-full !rounded-full bg-white pl-10 text-xs"
              value={sequence ?? ""}
              onChange={(e) =>
                // upcase and remove whitespace
                setSequence(e.target.value.toUpperCase().replace(/\s/g, ""))
              }
              placeholder="Enter your sequence here"
            />
            <button className="absolute left-4" type="submit">
              <SearchIcon className="h-4 w-4 rounded-full text-black text-noir-600" />
            </button>
          </form>
        )}
      </header>
      <main className="flex-1 bg-noir-200 px-8 py-4">{children}</main>

      <Footer className="mt-auto h-24" />
    </div>
  );
};
