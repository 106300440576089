import { useParams } from "react-router-dom";
import { SearchResults } from "@/components/SearchResults/SearchResults";
import { Shell } from "@/components/Shell/Shell";
import { z } from "zod";

export const SearchPage = () => {
  const params = useParams();
  const paramsSchema = z.object({
    sequence: z.string(),
  });
  const { sequence } = paramsSchema.parse(params);

  return (
    <Shell>
      <div className="mx-auto flex h-full max-w-7xl flex-col justify-start text-zinc-900">
        {sequence && <SearchResults sequence={sequence} />}
      </div>
    </Shell>
  );
};
