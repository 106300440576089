import { AlignmentRecord } from "@/api/alignment/schemas";
import { useHMMAnnotate } from "@/api/hmm_annotate/hooks";
import { HMMAnnotation, getColorForHMM } from "@/api/hmm_annotate/schemas";
import { ProteinRecord } from "@/api/protein_search/schemas";
import { FoldingCard } from "@/components/FoldingCard/FoldingCard";
import { GenomicContextViz } from "@/components/GenomicContextViz/GenomicContextViz";
import { Button } from "@/components/ui/button/button";
import { QUERY_COLOR, RETRIEVAL_COLOR } from "@/utils/colors";
import { cn } from "@/utils/strings";
import {
  Annotation as SeqAnnotation,
  SequenceViewer,
} from "@nitro-bio/sequence-viewers";
import {
  InfoIcon,
  LoaderCircleIcon,
  SquareArrowOutUpRightIcon,
} from "lucide-react";
import { ReactNode, useState } from "react";
import { Tooltip, TooltipContent, TooltipTrigger } from "../ui/tooltip";
import { CopyButton } from "./CopyButton";

export const ActiveCard = ({
  header,
  match,
  contig,
  alignment,
  isFetchingAlignment,
  alignmentError,
  size,
  isQuery,
  className,
}: {
  header?: ReactNode;
  match: ProteinRecord;
  contig?: ProteinRecord[];
  alignment: AlignmentRecord | undefined | null;
  isFetchingAlignment: boolean;
  alignmentError: Error | null;
  size: "mini" | "full";
  isQuery?: boolean;
  className?: string;
}) => {
  const [currentAnnotationIdx, setCurrentAnnotationIdx] = useState<
    number | null
  >(0);
  const { hmmAnnotateData, isFetchingHMMAnnotate, hmmAnnotateError } =
    useHMMAnnotate({ record: match });

  const hmmAnnotations =
    hmmAnnotateData?.result.find((x) => {
      return x.cdsData.cds_shorthand === match.cdsData.cds_shorthand;
    })?.annotations ?? [];

  return (
    <div className={cn(className, "flex flex-col")}>
      {header && <header className="col-span-12 min-h-16">{header}</header>}
      <div
        className={cn(
          "grid grid-cols-12 gap-2 rounded-md bg-molstar px-4 py-2",
        )}
      >
        <span className="overflow-show col-span-12 flex gap-2">
          {!isQuery && (
            <>
              {match.clipAnnotation && (
                <Tooltip>
                  <TooltipTrigger>
                    <Button
                      size="xs"
                      variant="outline"
                      className={cn(
                        "flex items-center gap-1 rounded-md border px-2 py-1 text-xs",
                      )}
                      asChild
                    >
                      <a
                        href={`https://www.uniprot.org/uniprot/${match.clipAnnotation.clipId}`}
                        target="_blank"
                      >
                        {match.clipAnnotation.clipId}
                        <SquareArrowOutUpRightIcon className="h-3 w-3 text-noir-600" />
                      </a>
                    </Button>
                  </TooltipTrigger>
                  <TooltipContent>Closest Swissprot Entry</TooltipContent>
                </Tooltip>
              )}
              <Tooltip>
                <TooltipTrigger>
                  <Button
                    size="xs"
                    variant="outline"
                    className="flex items-center gap-1 rounded-md border px-2 py-1 text-xs "
                    asChild
                  >
                    <a
                      /* Some sample ids are associated with different reference datasets, we condition on the id type and link to the appropriate resource */
                      href={
                        match.cdsData.sample_id.includes("ERZ")
                          ? `https://www.ebi.ac.uk/metagenomics/assemblies/{match.cdsData.sample_id}`
                          : `https://img.jgi.doe.gov/cgi-bin/m/main.cgi?section=TaxonDetail&page=taxonDetail&taxon_oid=${match.cdsData.sample_id}`
                      }
                      target="_blank"
                    >
                      Metadata
                      <SquareArrowOutUpRightIcon className="h-3 w-3 text-noir-600" />
                    </a>
                  </Button>
                </TooltipTrigger>
                <TooltipContent>Sample Metadata</TooltipContent>
              </Tooltip>
            </>
          )}
          {size === "mini" && (
            <CopyButton
              label={isQuery ? "Query Sequence" : "Retrieved Sequence"}
              textToCopy={() => match.sequence}
              buttonClassName="!text-brand-600 !shadow-none border border-noir-800 bg-inherit"
            />
          )}
        </span>

        {contig && (
          <div className="col-span-12 rounded-md border px-4 py-2">
            <h4 className="text-xs font-medium text-noir-500">
              Genomic Context
            </h4>
            <GenomicContextViz
              className="py-2"
              items={[match, ...contig].map((record) => ({
                id: record.cdsData.cds_shorthand,
                stack: record.cdsData.strand === "forward" ? 0 : 1,
                start: record.cdsData.start,
                end: record.cdsData.end,
                strand: record.cdsData.strand,
                sequence: record.sequence,
                clipAnnotation: record.clipAnnotation,
                isMatch:
                  match.cdsData.cds_shorthand === record.cdsData.cds_shorthand,
                operonPrediction: record.operonPrediction,
              }))}
            />
          </div>
        )}
        {!isQuery && (
          <AlignmentViz
            className={cn(
              size == "full" && "col-span-6 row-span-2",
              size == "mini" && "col-span-12",
            )}
            record={match}
            alignment={alignment}
            isFetchingAlignment={isFetchingAlignment}
            alignmentError={alignmentError}
            size={size}
            annotations={hmmAnnotations}
            setCurrentAnnotationIdx={setCurrentAnnotationIdx}
            currentAnnotationIdx={currentAnnotationIdx}
          />
        )}
        <FoldingCard
          className={cn(
            "row-span-2",
            size == "mini" && "col-span-12",
            size == "full" && "col-span-6",
          )}
          protein_seq={match.sequence}
          annotations={hmmAnnotations}
          isFetchingHMMAnnotate={isFetchingHMMAnnotate}
          hmmAnnotateError={hmmAnnotateError}
          setCurrentAnnotationIdx={setCurrentAnnotationIdx}
          currentAnnotationIdx={currentAnnotationIdx}
          structureHexColor={isQuery ? QUERY_COLOR : RETRIEVAL_COLOR}
        />
      </div>
    </div>
  );
};
export const AlignmentViz = ({
  record,
  alignment,
  isFetchingAlignment,
  alignmentError,
  className,
  size,
  annotations,
  currentAnnotationIdx,
  setCurrentAnnotationIdx,
}: {
  record: ProteinRecord;
  alignment: AlignmentRecord | null | undefined;
  isFetchingAlignment: boolean;
  alignmentError: Error | null;
  size: "mini" | "full";
  annotations: HMMAnnotation[];
  className?: string;
  currentAnnotationIdx: number | null;
  setCurrentAnnotationIdx: (idx: number | null) => void;
}) => {
  const charClassName = ({
    sequenceIdx,
  }: {
    sequenceIdx: number;
    base: { base: string };
  }) => {
    if (!alignment) {
      return "pt-2 text-brand-600";
    }
    if (sequenceIdx === 0) {
      return "pt-2 text-noir-600";
    } else if (sequenceIdx === 1) {
      return "text-noir-400 !opacity-100";
    } else {
      return "text-brand-600";
    }
  };
  const statPlaceholder = isFetchingAlignment ? "..." : "N/A";
  const stats = [
    {
      name: "BLASTp % Identity",
      stat: alignment
        ? `${alignment.percent_identity.toFixed(2)} %`
        : statPlaceholder,
    },
    {
      name: "Query Coverage",
      stat: alignment
        ? `${alignment.query_coverage.toFixed(2)} %`
        : statPlaceholder,
    },
  ];
  const seqAnnotations: SeqAnnotation[] = annotations.map((ann, idx) => ({
    text: ann.label,
    type: `${ann.label}-${idx}`,
    direction: "forward",
    start: ann.start,
    stack: idx,
    end: ann.end,
    className: cn(
      `bg-[${getColorForHMM(ann)}] text-white cursor-pointer`,
      currentAnnotationIdx === idx ? "opacity-100" : "opacity-60",
    ), // Normally, we can't interpolate tailwind classnames, because the classes are statically generated. To get around this, we need to make sure all possible classes are generated by updating the tailwind config
    onClick: () => setCurrentAnnotationIdx(idx),
  }));

  return (
    <div className={cn("flex flex-col", "", className)}>
      <div className={cn("", "flex flex-col gap-1")}>
        <div className="grid grid-cols-2 gap-1">
          {stats.map((item) => (
            <div
              key={item.name}
              className={cn("overflow-hidden rounded-md border px-4 py-2")}
            >
              <dt className="truncate text-xs font-medium text-noir-500">
                {item.name}
              </dt>
              <dd className="mt-1 font-semibold tracking-tight text-noir-900">
                {item.stat}
              </dd>
            </div>
          ))}
        </div>
        {isFetchingAlignment && (
          <p className="flex animate-pulse gap-1 text-xs text-brand-600">
            <LoaderCircleIcon className="my-auto h-4 w-4 animate-spin text-brand-400" />
          </p>
        )}
        {alignmentError && (
          <p className="font-semibold text-red-600">
            Internal error in sequence alignment
          </p>
        )}
        <p className="text-xs">
          {!isFetchingAlignment &&
            !alignmentError &&
            !alignment &&
            "No alignment between sequences"}
        </p>
      </div>

      {size === "full" && (
        <div className={cn("flex flex-col")}>
          <span className="flex flex-wrap items-center ">
            <CopyButton
              label={"Retrieved Sequence"}
              textToCopy={() => record.sequence}
              buttonClassName="!text-brand-600 !shadow-none border border-noir-800 bg-inherit"
            />
            {alignment && (
              <Tooltip>
                <TooltipTrigger>
                  <Button size="xs" variant="ghost">
                    <InfoIcon className="h-3 w-3 text-noir-600" />
                  </Button>
                </TooltipTrigger>
                <TooltipContent>
                  <p>Needleman–Wunsch global alignment</p>
                </TooltipContent>
              </Tooltip>
            )}
          </span>

          {alignment ? (
            <SequenceViewer
              noValidate
              sequences={[
                alignment.query,
                createMidline({ query: alignment.query, ref: alignment.ref }),
                alignment.ref,
              ]}
              annotations={seqAnnotations}
              selection={null}
              charClassName={charClassName}
              containerClassName="max-h-[400px] overflow-y-scroll !text-xs"
            />
          ) : (
            <SequenceViewer
              noValidate
              sequences={[record.sequence]}
              annotations={seqAnnotations}
              selection={null}
              charClassName={charClassName}
              containerClassName="max-h-[400px] overflow-y-scroll !text-xs"
            />
          )}
        </div>
      )}
    </div>
  );
};

const createMidline = ({ ref, query }: { ref: string; query: string }) => {
  const zipped = ref.split("").map((r, i) => [r, query[i]]);
  return zipped
    .map(([r, q]) => {
      if (r === q) {
        return "|";
      }
      if (r === "-" || q === "-") {
        return " ";
      }
      return "X";
    })
    .join("");
};
