import { useQuery } from "@tanstack/react-query";
import { gaiaAPI } from "@/utils/wretch";
import { WarmUpResponseSchema } from "./schemas";

export const useWarmUp = () => {
  const queryFn = async () => {
    const res = await gaiaAPI.url("/warm_up_endpoints").get().json();
    const output = WarmUpResponseSchema.parse(res);
    return output;
  };

  const { data, isFetching, error } = useQuery({
    queryKey: ["warmUp"],
    queryFn: queryFn,
  });

  return {
    warmUpData: data,
    isFetchingWarmUp: isFetching,
    warmUpError: error as Error | null,
  };
};
