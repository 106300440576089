import { TooltipProvider } from "@/components/ui/tooltip";
import { QueryClientProvider, QueryClient } from "@tanstack/react-query";
import { BrowserRouter, MemoryRouter } from "react-router-dom";
import { AuthProvider } from "./auth/AuthProvider";
const isDevServer = import.meta.env.MODE === "development";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: isDevServer ? false : 1, // Retry up to 1 times if not on a dev server
      refetchOnWindowFocus: false,
    },
  },
});

export const Providers = ({ children }: { children: React.ReactNode }) => {
  return (
    <QueryClientProvider client={queryClient}>
      <BrowserRouter>
        <AuthProvider>
          <TooltipProvider delayDuration={100}>{children} </TooltipProvider>
        </AuthProvider>
      </BrowserRouter>
    </QueryClientProvider>
  );
};

export const StoryProviders = ({
  children,
  initialEntries,
}: {
  children: React.ReactNode;
  initialEntries?: string[];
}) => {
  return (
    <QueryClientProvider
      client={
        new QueryClient({
          defaultOptions: {
            queries: {
              retry: false,
              refetchOnWindowFocus: false,
            },
          },
        })
      }
    >
      <MemoryRouter initialEntries={initialEntries}>
        <AuthProvider>
          <TooltipProvider delayDuration={100}>{children}</TooltipProvider>
        </AuthProvider>
      </MemoryRouter>
    </QueryClientProvider>
  );
};
