export const strIntoArray = <T>(str: string, arr: T[]) => {
  const stringToId = (str: string) => {
    let hash = 0;
    for (let i = 0; i < str.length; i++) {
      hash = str.charCodeAt(i) + ((hash << 5) - hash);
    }
    return Math.abs(hash);
  };
  const idx = stringToId(str) % arr.length;
  const item = arr[idx];
  return item;
};
