import { saveAs } from "file-saver";
import { Button } from "./ui/button/button";
import {
  cdsDataToString,
  ProteinRecord,
  ProteinSearchResponseOutput,
} from "@/api/protein_search/schemas";
import JSZip from "jszip";
import { cn } from "@/utils/strings";

const DownloadButtons = ({
  data,
  className,
}: {
  data?: ProteinSearchResponseOutput;
  className?: string;
}) => {
  const downloadAllSequences = (data: ProteinSearchResponseOutput) => {
    const matches: ProteinRecord[] = data.match_data.map(
      (match_datum) => match_datum.match,
    );
    const fastaFile = convertToFasta(matches);

    const blob = new Blob([fastaFile], { type: "text/text;charset=utf-8" });
    const fileName = `gaia_${getCurrentDateTimeString()}_retrieved_seqs.fa`;

    saveAs(blob, fileName);
  };

  const downloadAllContexts = (data: ProteinSearchResponseOutput) => {
    const files: { blob: Blob; fileName: string }[] = data.match_data.map(
      (datum, datum_idx) => {
        const fastaFile = convertToFasta(datum.contig);
        const blob = new Blob([fastaFile], { type: "text/text;charset=utf-8" });
        const fileName = `gaia_${getCurrentDateTimeString()}_retrieved_seqs_${datum_idx}.fa`;
        return { blob, fileName };
      },
    );
    // create zipfile of all the above files
    const zip = new JSZip();
    files.forEach((file) => {
      zip.file(file.fileName, file.blob);
    });
    zip.generateAsync({ type: "blob" }).then((content: Blob) => {
      saveAs(
        content,
        `gaia_${getCurrentDateTimeString()}_retrieved_contexts.zip`,
      );
    });
  };

  return (
    <div className={cn("flex gap-1", className)}>
      <Button
        variant="outline"
        className="truncate"
        size="xs"
        disabled={!data}
        onClick={() => {
          if (!data) {
            return;
          }
          downloadAllSequences(data);
        }}
      >
        All retrieved sequences (.fa)
      </Button>
      <Button
        variant="outline"
        className="truncate"
        size="xs"
        disabled={!data}
        onClick={() => {
          if (!data) {
            return;
          }
          downloadAllContexts(data);
        }}
      >
        All retrieved contexts (.zip)
      </Button>
    </div>
  );
};
const convertToFasta = (records: ProteinRecord[]): string => {
  const res = records.map((rec) => {
    const header = `>${cdsDataToString(rec.cdsData)} ${rec.clipAnnotation?.clipDescription ?? "Unannotated"}`;
    const body = rec.sequence;
    return [header, body].join("\n");
  });
  return res.join("\n");
};
function getCurrentDateTimeString(): string {
  const now = new Date();

  const year = now.getFullYear();
  const month = String(now.getMonth() + 1).padStart(2, "0");
  const day = String(now.getDate()).padStart(2, "0");
  const hours = String(now.getHours()).padStart(2, "0");
  const minutes = String(now.getMinutes()).padStart(2, "0");

  return `${year}_${month}_${day}_${hours}_${minutes}`;
}
export default DownloadButtons;
