import * as Sentry from "@sentry/react";
import ReactDOM from "react-dom/client";
import App from "./App";
import "./index.css";
import { Providers } from "./providers/providers";

Sentry.init({
  dsn: import.meta.env.VITE_SENTRY_DSN,
  tunnel: "/sentry_tunnel",
  integrations: [
    Sentry.replayIntegration({
      maskAllText: false,
      blockAllMedia: false,
    }),
    // The following is all you need to enable canvas recording with Replay
    Sentry.replayCanvasIntegration(),
    Sentry.feedbackIntegration({
      // Additional SDK configuration goes in here, for example:
      colorScheme: "system",
      triggerLabel: "Share Feedback",
      formTitle: "Share Feedback",
      submitButtonLabel: "Send Feedback",
      messagePlaceholder:
        "What feedback do you have? If something went wrong, what happened, and what did you expect?",
      successMessageText: "Thanks for your feedback!",
    }),
  ],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

ReactDOM.createRoot(document.getElementById("root")!).render(
  <Providers>
    <App />
  </Providers>,
);
