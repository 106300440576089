import { useQuery } from "@tanstack/react-query";
import { gaiaAPI } from "@/utils/wretch";
import {
  FoldingRequestSchema,
  FoldingResponseSchema,
  ProteinSchema,
} from "./schemas";
import { useToken } from "@/api/auth/hooks";
import { useNavigate } from "react-router-dom";

export const useFolding = ({ protein_seq }: { protein_seq: string | null }) => {
  const token = useToken();
  const navigate = useNavigate();
  const queryFn = async () => {
    if (!protein_seq) {
      return;
    }
    if (!ProteinSchema.safeParse(protein_seq)) {
      throw new Error("Invalid protein sequence");
    }
    const query = FoldingRequestSchema.parse({ protein_seq });
    const res = await gaiaAPI
      .auth(`Bearer ${token}`)
      .query(query)
      .get("/folding")
      .unauthorized(() => {
        alert("Your session has expired, please login again");
        navigate("/login");
      })

      .json();

    const parsed = FoldingResponseSchema.parse(res);
    return parsed;
  };
  const { data, isFetching, error } = useQuery({
    queryKey: ["folding", protein_seq],
    enabled: !!protein_seq,
    queryFn: queryFn,
    // we want to cache the folding result so we don't refold things on component mount
    staleTime: 1000 * 60 * 60 * 1, // 1 hour
  });

  return {
    foldingData: data,
    isFetchingFolding: isFetching,
    foldingError: error as Error | null,
  };
};
