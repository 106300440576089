import { z } from "zod";
import { CDSDataSchema } from "@/api/protein_search/schemas";
import { strToColor } from "@/utils/colors";

export const HMMAnnotationSchema = z.object({
  label: z.string(),
  start: z.number(),
  end: z.number(),
  pfamId: z.string().nullable(),
});
export type HMMAnnotation = z.infer<typeof HMMAnnotationSchema>;

export const HMMAnnotateRequestRecordSchema = z.object({
  cdsData: CDSDataSchema,
  sequence: z.string(),
});
export const HMMAnnotateResponseRecordSchema = z.object({
  cdsData: CDSDataSchema,
  sequence: z.string(),
  annotations: z.array(HMMAnnotationSchema),
});
export type HMMData = z.infer<typeof HMMAnnotateResponseRecordSchema>;

export const HMMAnnotateRequestSchema = z.object({
  sequences: z.array(HMMAnnotateRequestRecordSchema),
});
export const HMMAnnotateResponseSchema = z.object({
  result: z.array(HMMAnnotateResponseRecordSchema),
  status: z.union([z.literal("ok"), z.literal("error")]),
});
export type HMMAnnotateResponse = z.infer<typeof HMMAnnotateResponseSchema>;

export const getColorForHMM = (ann: HMMAnnotation) => {
  return strToColor(`${ann.label}-${ann.pfamId}`);
};
