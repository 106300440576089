import { useAlignment } from "@/api/alignment/hooks";
import { useProteinSearch } from "@/api/protein_search/hooks";
import {
  CDSData,
  ProteinSearchQueryParams,
  UNANNOTATED_CLIP_LABEL,
} from "@/api/protein_search/schemas";
import { UMAP } from "@/components/UMAP/UMAP";
import { cn } from "@/utils/strings";

import { LoaderCircleIcon } from "lucide-react";
import { useState } from "react";
import { useSearchParams } from "react-router-dom";
import { ActiveCard } from "./ActiveCard";
import { PaginatedAccordion } from "./PaginatedAccordion";

export const SearchResults = ({
  sequence,
  className,
}: {
  sequence: string;
  className?: string;
}) => {
  const [searchParams] = useSearchParams();
  const searchParamsObject = Object.fromEntries(searchParams.entries());

  const { mockEmbedding, contextBefore, contextAfter, debug } =
    ProteinSearchQueryParams.parse(searchParamsObject);

  const { proteinSearchData, isFetchingProteinSearch, proteinSearchError } =
    useProteinSearch({
      sequence,
      mockEmbedding,
      contextBefore,
      contextAfter,
      debug,
    });
  const { alignmentData, isFetchingAlignment, alignmentError } = useAlignment({
    query: sequence,
    match_list: proteinSearchData?.match_data?.map((record) => record.match),
  });
  const findAlignmentByCDSData = (cdsData: CDSData) => {
    const res =
      alignmentData?.records.find((result) => {
        return result.refCDSData.cds_shorthand === cdsData.cds_shorthand;
      }) ?? null;
    return res;
  };
  const containerClassName = cn(
    "px-8 min-h-80 flex flex-col gap-4 items-center justify-center ",
    className,
  );
  const [activeMatchCdsShorthand, setActiveMatchCdsShorthand] = useState<
    string | null
  >(null);
  const activeMatch = proteinSearchData?.match_data?.find(
    (x) => x.match.cdsData.cds_shorthand === activeMatchCdsShorthand,
  );

  if (isFetchingProteinSearch) {
    return (
      <div className={cn(containerClassName, "animate-spin text-noir-600")}>
        <LoaderCircleIcon className="h-12 w-12 text-noir-400" />
      </div>
    );
  }
  if (proteinSearchError) {
    return (
      <div className={cn(containerClassName, "text-center text-red-700")}>
        An internal error occured during protein search.
      </div>
    );
  }
  if (!proteinSearchData) {
    return (
      <div className={cn(containerClassName, "text-center text-noir-600")}>
        No results found for query
      </div>
    );
  }

  return (
    <div className={className}>
      <section className="grid grid-cols-1 gap-2 md:grid-cols-12">
        <UMAP
          className={"md:col-span-6"}
          queryUMAPDatum={proteinSearchData.query.umap_datum}
          matchUMAPData={proteinSearchData.match_data.map(
            (x) => x.match_umap_datum,
          )}
          alignmentData={alignmentData?.records}
          isFetchingAlignment={isFetchingAlignment}
          alignmentError={alignmentError}
          onClick={(cds_shorthand) => {
            setActiveMatchCdsShorthand(cds_shorthand);
          }}
        />
        {activeMatch ? (
          <ActiveCard
            header={
              <div className="flex flex-col justify-start ">
                <div className="flex items-start gap-2 font-semibold">
                  {activeMatch.match.clipAnnotation?.clipDescription ??
                    UNANNOTATED_CLIP_LABEL}{" "}
                  <p className="text-noir-400">
                    {activeMatch.score.toFixed(3)}
                  </p>
                </div>
                <p className="text-left text-xs font-light">
                  {activeMatch.taxonomy.phylum}
                </p>
                {/* needed to match the spacing created by the description subtitle in the UMAP component */}
                <p className="pre text-left text-xs font-light opacity-0">
                  Spacer
                </p>
              </div>
            }
            className={"md:col-span-6"}
            match={activeMatch.match}
            contig={activeMatch.contig}
            alignment={findAlignmentByCDSData(activeMatch.match.cdsData)}
            isFetchingAlignment={isFetchingAlignment}
            alignmentError={alignmentError}
            size="mini"
          />
        ) : (
          <ActiveCard
            isQuery={true}
            header={
              <div className="flex flex-col justify-start ">
                <div className="flex items-start gap-2 font-semibold">
                  Query
                </div>
                <p className="text-left text-xs font-light">Your submission</p>
                {/* needed to match the spacing created by the description subtitle in the UMAP component */}
                <p className="pre text-left text-xs font-light opacity-0">
                  Spacer
                </p>
              </div>
            }
            className={"md:col-span-6"}
            match={proteinSearchData.query.record}
            alignment={undefined}
            isFetchingAlignment={false}
            alignmentError={null}
            size="mini"
          />
        )}
      </section>

      <div className="pt-8">
        <PaginatedAccordion proteinSearchData={proteinSearchData} />
      </div>
    </div>
  );
};
